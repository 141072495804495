import { PrivacyControllerService, PrivacyVo } from '@/__generated__/FrontApi';
import Container from '@/components/Container';
import Section from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { PrivacyContainer } from '@/components/Term';
import { Tit } from '@/components/Titles';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { Link, PageProps } from 'gatsby';
import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

const PolicyContent = styled.div`
  img {
    margin: 0 aut0;
  }
`;
export interface PolicyData {
  indexNumber: number;
  version: string;
  enforceDate: string;
  content: string;
  useYn: string;
  firstRegisterDate: string;
  lastModifyDate: string;
  latestVersion: string;
}

const CctvPolicy: FC<PageProps> = () => {
  const [latestPolicy, setLatestPolicy] = useState<PolicyData>();
  const [policyList, setPolicyList] = useState<PolicyData[]>([]);

  // 영상처리기기 관련방침 로드
  const loadPolicyList = useCallback(async () => {
    try {
      const {
        data: cctvPolicy,
      } = await PrivacyControllerService.getPrivacyCctvPolicyListUsingGet();
      setLatestPolicy(
        cctvPolicy.filter((cctv: PrivacyVo) => cctv.latestVersion === 'Y')[0],
      );
      setPolicyList(
        cctvPolicy.map((cctv: PrivacyVo) => ({
          id: cctv.indexNumber,
          version: cctv.version,
          enforceDate: cctv.enforceDate,
          content: cctv.content,
          useYn: cctv.useYn,
          latestVersion: cctv.latestVersion,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadPolicyList();
  }, [loadPolicyList]);

  const currentKey = 2;

  return (
    <LayoutWithTitle title="개인정보 처리방침">
      <Section className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">
                영상정보처리기기 운영 · 관리 방침 v{latestPolicy?.version}
              </Tit>
            </h2>
            <Tit size="s3">(시행일 {latestPolicy?.enforceDate})</Tit>
          </SectionHeader>
          <Tit size="s1-5" color="sky" weight="normal">
            유니세프한국위원회는 개인정보를 소중히 다루고 있습니다
          </Tit>

          <PrivacyContainer>
            <div className="tab-btn">
              <Swiper
                slidesPerView={3}
                navigation={false}
                scrollbar={{ draggable: false }}
                noSwiping
                allowTouchMove={false}
                breakpoints={{
                  641: {
                    slidesPerView: 3,
                    initialSlide: currentKey,
                  },
                  320: {
                    allowTouchMove: true,
                    slidesPerView: 2.5,
                    initialSlide: currentKey,
                  },
                }}
              >
                <SwiperSlide>
                  <Link to="/privacy#tab-section" className="link-item">
                    개인정보 처리방침
                  </Link>
                </SwiperSlide>
                <SwiperSlide>
                  <Link to="/privacy-corp#tab-section" className="link-item">
                    위탁업체
                  </Link>
                </SwiperSlide>
                <SwiperSlide>
                  <Link
                    to="/cctv-policy#tab-section"
                    className="link-item _active"
                  >
                    영상정보처리기기 <br />
                    운영 · 관리 방침
                  </Link>
                </SwiperSlide>
              </Swiper>
            </div>
            <PolicyContent
              dangerouslySetInnerHTML={{ __html: latestPolicy?.content }}
            />
            <article>
              <div className="history-box">
                <ul>
                  {policyList.map((row) => (
                    <li>
                      <Link
                        to={`/cctv-policy/${row.version}`}
                        className="link-item"
                      >
                        유니세프한국위원회 영상정보처리기기 운영·관리 방침 v
                        {row.version}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </article>
          </PrivacyContainer>
        </Container>
      </Section>
    </LayoutWithTitle>
  );
};

export default CctvPolicy;
